import React from "react";

import posed from "react-pose";

import WorkInProgress from "../components/wip";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Transition = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

const AppsPage = location => (
  <Layout location={location}>
    <SEO
      title="Apps"
      keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
    />

    <WorkInProgress />
  </Layout>
);

export default AppsPage;
